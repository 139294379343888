.feedback-page {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.main {
    align-items: center;
}

.feedback-section {
    background-color: transparent;
}

.form-div {
    width: 400px;
    min-width: 250px;
    color: white;
    background-color: rgba(52, 52, 52, 0.75);
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 14px;
}

@media screen and (max-width:450px) {
    .form-div {
        width: 300px;
    }

    .content div {
        padding-right: 0.5rem !important;
        padding-left: 0.5rem !important;
    }
}

@media screen and (max-width:767px) {
    .feedback-page-title {
        font-size: 45px !important;
    }
}

.feedback-section input[type=radio] {
    accent-color: rgba(236, 169, 53, 1);
}

.feedback-section .form-check {
    display: inline;
    margin-left: 1rem;
}


.main p {
    color: white;
    text-align: center;
}


.main button {
    border-radius: 15px;
    border: none;
    width: fit-content;
    padding: 0.7rem;
    padding-right: 2.5rem;
    padding-left: 2.5rem;
}

.main a {
    color: black;
    font-weight: 700;
}

.feedback-page-title {
    color: white;
    margin-bottom: 2rem;
    font-size: 62px;
}

.submit-feedback {
    display: flex;
    justify-content: center;
}

.feedback-section textarea,
.feedback-section textarea:focus {
    background-color: rgba(149, 149, 149, 0.4);
    color: white;
    border-radius: 10px;
    border: 2px white solid;
    resize: none;
}


.feedback-title {
    background-color: rgba(149, 149, 149, 0.4) !important;
    color: white !important;
    border-radius: 10px !important;
    border: 2px white solid !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px #959595 inset !important;
    -webkit-text-fill-color: rgba(255, 255, 255, 0.7) !important;
}

.feedback-section textarea::placeholder, .feedback-title::placeholder {
    color: rgba(255, 255, 255, 0.8) !important;
}

.form-submission-message {
    text-align: center;
}

#submit-feedback-button {
    background-color: rgba(236, 169, 53, 1);
    color: black;
    border: none;
    font-weight: 700;
    font-style: normal;
    line-height: 20px;
    font-size: 14px;
    border-radius: 20px;
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
    padding-right: 2rem;
    padding-left: 2rem;
}

.form-submission-message {
    text-align: center;
}

.main button {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    padding-top: 0;
    padding-bottom: 0;
    border-radius: 0;
    border: none;
}

.main button:focus {
    background-color: transparent;
    color: black;
}

#feedback-dropdown {
    background-color: transparent;
}