  .container {
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0;
    margin-top: .5rem;
    margin-bottom: .5rem;
  }

  .main-container 
  {
    width: 50rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    height: auto; 
  }

  .card-title {
    text-align: center;
    flex-direction: column;
    color: black;
    font-size: 35px;
    text-transform: none;
    font-weight: none;
    margin-top: 1rem;
    margin-bottom: 0rem;
  }

  .toggle-box {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem;
    border: 0.1rem solid #007bff;
    border-radius: 0.5rem;
    width: 40rem;
    height: 5rem;
    color:rgb(0,0,0);
    font-size: 1.2rem;
    background-color: rgba(255,255,255, .33);
    
  }

  .toggle-box label {
    margin-right: 1rem;
  }

  .toggle-box input[type="checkbox"] {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    width: 4rem;
    height: 2rem;
    background-color: #ccc;
    position: relative;
    border-radius: 1rem;
    outline: none;
    cursor: pointer;
  }

  .toggle-box input[type="checkbox"]::before {
    content: '';
    position: absolute;
    width: 1.6rem;
    height: 1.6rem;
    background-color: #fff;
    border-radius: 50%;
    top: 50%;
    transform: translateY(-50%);
    left: 0.2rem;
    transition: 0.3s;
  }

  .toggle-box input[type="checkbox"]:checked::before {
    left: calc(100% - 1.8rem);
    background-color: #007bff;
  }

