.feature-card {
    width: 230px;
    height: 160px;
    border-radius: 10px;
    overflow: hidden;
    background-color: black;
    border: none;
    padding: 0;
}

.feature-title {
    color: white;
    text-transform: uppercase;
    padding-left: 1em;
    margin-bottom: 0;
    height: 25%;
    display: flex;
    align-items: center;
    font-weight: bold;
}

.feature-image {
    height: 75%;
    background-repeat: no-repeat;
    background-size: cover;
    width: 230px;
}