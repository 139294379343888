.flex-container {
    display: flex;
    flex-direction: row;
    background-repeat: no-repeat;
}

.flex-left {
    min-height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 1.5rem;
    width: 50%;
}

.text-div {
    border-radius: 25px;
    background: rgba(52, 52, 52, .75);
    color: white;
    text-align: center;
    align-items: center;
    padding: 1rem;
    margin: 1rem;
}

.feature-instructions {
    text-align: left;
}

@media screen and (max-width: 480px) {
    .flex-left {
        margin-left: 1rem;
        width: 75%;
    }

    .flex-container {
        justify-content: center;
    }
}
