.about-page {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.about-page-title {
    text-align: center;
    color: white;
    margin-bottom: 10rem;
    font-size: 62px;
}

.flipping-cards-container {
    flex-direction: row;
    justify-content: space-around;
    width: 100%;
}

.contact-us-button {
    position: absolute;
    bottom: 2rem;
    display: flex;
    align-items: center;
    padding-left: 1.4rem;
    padding-right: 1.4rem;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    border-radius: 20px;
    border: none;
}

.contact-us-button a {
    color: black;
    font-weight: 700;
    font-size: large;
}


.faq-section {
    background-color: black;
    padding-bottom: 3rem;
}

.faq-section h1 {
    text-align: center;
    color: white;
    margin-top: 3rem;
    margin-bottom: 3rem;
    font-size: 62px;
    padding: 0.5rem;
}


@media screen and (max-width:450px) {
    .flipping-cards-container {
        width: 100%;
        flex-direction: column;
        align-items: center;
    }

    .flip-card {
        height: 10rem !important;
        margin-top: 3rem;
        width: 80% !important;
    }

    .about-page-title {
        margin-bottom: 1rem;
    }
}


.flip-card {
    background-color: transparent;
    width: 40%;
    height: 24rem;
    perspective: 1000px;
}


.flip-card p {
    font-size: 18px;
    position: absolute;
    top: 0;
    padding: 1rem;
}
  
.flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
}

.flip-card:hover .flip-card-inner {
    transform: rotateY(180deg);
}

.flip-card-front, .flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    border-radius: 24px;
}

.flip-card-front {
    background-color: rgba(52, 52, 52, 0.75);
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 2rem;
}

.flip-card-back {
    background-color: rgba(52, 52, 52, 0.75);
    color: white;
    transform: rotateY(180deg);
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;
}