html {
  scroll-behavior: smooth;
}

.containerMain {
  display: grid;
  justify-content: center;
  min-width: auto;
  text-align: center;
  /*CSS Smooth Scroll */
  overflow-y: scroll;
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
}

.containerMainMobile {
  display: grid;
  justify-content: center;
  min-width: 22.5em;
  max-width: 22.5em;
  text-align: center;
  /*CSS Smooth Scroll */
  overflow-y: scroll;
  scroll-behavior: smooth;
  scroll-snap-type: y mandatory;
  height: 100vh;
}

.toastMain {
  min-width: 55em;
}

.toastMobile {
  min-width: 22.5em;
  max-width: 22.5em;
}

@font-face {
  font-family: "Roboto-Light";
  src: url("./fonts/Roboto-Light.woff") format("woff"),
    /* Modern Browsers */
    url("./fonts/Roboto-Light.woff2") format("woff2");
  /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}

.button {
  border: none;
  color: black;
  padding: 16px 32px;
  text-align: center;
  text-decoration: none;
  display: inline-block;
  font-size: 16px;
  margin: 4px 2px;
  transition-duration: 0.4s;
  cursor: pointer;
}

* {
  box-sizing: border-box;
}

.image {
  border-radius: 25px;
  width: 75%;
  height: auto;
  float: center;
  position: relative;
  top: auto;
  opacity: 0.8;
}

.center-text {
  text-align: center;
  display: block;
}

body {
  margin: 0;
}

/* Create two equal columns that floats next to each other */
.column {
  float: left;
  width: 50%;
  padding: 15px;
  position: relative;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

section {
  display: grid;
  flex-direction: column no-repeat center center/cover;
  height: 100vh;
  min-width: auto;
}


/* Responsive layout - makes the two columns stack on top of each other instead of next to each other */
@media screen and (max-width:600px) {
  .react-slideshow-container .column {
    width: 50%;
    float: left;
    padding: 15px;
    position: relative;
  }
}

@media screen and (min-width: 600px) {
  .react-slideshow-container .column {
    float: left;
    width: 50%;
    padding: 15px;
    position: relative;
  }
}
.indicator {
  cursor: pointer;
  padding: 10px;
  text-align: center;
  border: 1px #666 solid
}

.indicator.active {
  color: #fff;
  background: #666;
}