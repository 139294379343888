.accordion .button {
    width: 100%;
    background-color: transparent;
    border-bottom: 1px solid white;
    color: white;
    display: flex;
    margin-bottom: 16px;
    justify-content: space-between;
    padding-left: 0;
    align-items: center;
}

.accordion .active-button {
    width: 100%;
    background-color: transparent;
    border: none;
    color: white;
    display: flex;
    margin-bottom: 16px;
    justify-content: space-between;
    padding-left: 0;
    align-items: center;
}

.active_accordion_icon {
    transform: rotateX(180deg);
    padding-right: 24px;
}

.answer_wrapper {
    height: 0;
    overflow: hidden;
    transition: height ease 0.2s;
}

.answer_wrapper.open {
    height: auto;
}

.answer {
    color: white;
    border-bottom: 1px solid white;
}

.answer li {
    background-color: black;
    color: white;
    font-size: 16px !important;
}