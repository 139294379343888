.circular-button {
    display: inline-block;
    margin: 1rem;
}

.disabled-circular-button-logo {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%); 
}

.disabled-circular-button-logo:hover {
    cursor: not-allowed !important; 
}

.circular-button-logo {
    height: 75px;
    width: 75px;
    border-radius: 50% !important;
}

.logo-text {
    text-align: center;
    margin-top: 1rem;
}

.active-circular-button-logo {
    border: 4px solid rgba(236, 169, 53, 1) !important;
}

.active-logo-text {
    color:rgba(236, 169, 53, 1);
    text-align: center;
    margin-top: 1rem;
}