.sro-page {
    display: flex;
    flex-direction: column;
    width: 100%;
    display: flex;
    justify-content: center;
    height: 100vh;
    background-color: black;
}


.sro-page-title {
    text-align: center;
    color: white;
    font-size: 62px;
}

.main {
    display: flex;
    flex-direction: column;
    justify-content: center;

}

.sro-container {
    width: 50rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;
    height: 75vh; 
}

.sro-container-content {
    display: flex;
    justify-content: center;
    gap: 1rem;
    flex-wrap: wrap;
}

.sro-sequences-container {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    /* overflow-y: scroll; */
    overflow-y: auto;
}

/* if screen size smaller than 930px */
@media screen and (max-width: 930px) {
    .sro-container {
        width: 20rem;
    }
}

.sro-sequence-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    /* margin-bottom: 2rem; */
    background-color: rgba(255, 255, 255, 0.4);
    border-radius: 5px;
    padding: 1rem;
    /* margin: 1rem; */
    width: 100%;
}

.sro-btn-row {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    width: 100%;
    flex-wrap: wrap;
}

.sro-btn-row .MuiFilledInput-underline:before {
    border-bottom: none;

}

.sro-btn-row .MuiInputBase-root {
    border-radius: 12px;
}

.sro-day-row {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    /* gap: 1rem; */
    margin: 1rem;
    width: 80%;
    flex-wrap: wrap;
}

@media screen and (max-width:930px) {
    .sro-day-row button {
        width: 40px;
        height: 40px;
        /* flex-direction: column; */
    }

    .sro-day-row {
        width: 100%;
    }

    .sro-sequence-container {
        padding: 0.5rem;
    }
}


@media screen and (max-width:640px) {
    .sro-page-title {
        font-size: 42px;
    }
}
.sro-modal-container {
    background-color: white;
    border-radius: 5px;
    padding: 1rem;
    max-width: 500px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
}
