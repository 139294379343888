.sdk-page {
    display: flex;
    flex-direction: column;
    width: 100%;
}

.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


@media screen and (max-width:450px) {
    .sdk-page-title {
        margin-bottom: 5rem !important;
    }
}


#sdk-text {
    width: 70%;
    padding-right: 3.5rem;
    padding-left: 3.5rem;
    color: white;
    font-weight: 500;
    font-size: 28px;
    text-shadow: 2px 2px 4px #000000;
    margin-bottom: 5rem !important;
    text-align: center;
}


.main button {
    border-radius: 15px;
    border: none;
    width: fit-content;
    padding: 0.7rem;
    padding-right: 2.5rem;
    padding-left: 2.5rem;
}

.main a {
    color: black;
    font-weight: 700;
}

.sdk-page-title {
    text-align: center;
    color: white;
    margin-bottom: 5rem;
    font-size: 62px;
}