.download-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.download-page-title {
    text-align: center;
    max-width: 650px;
    min-width: 200px;
    margin:0 auto;
    color: white;
    font-size: 62px;
    margin-bottom: 8rem;
}

.download-page-buttons {
    display: flex;
    justify-content: center;
    align-items: center
}

.download-page-buttons img {
    height: 30px;
    margin-left: 10px;
}

.download-page-buttons a {
    color: black;
    font-weight: 700;
    font-size: x-large;
}

.download-page-buttons button {
    display: flex;
    align-items: center;
    padding-left: 1.4rem;
    padding-right: 1.4rem;
    padding-top: 0.8rem;
    padding-bottom: 0.8rem;
    border-radius: 20px;
    border: none;
    margin-bottom: 1rem;
    margin-right: 10%;
    margin-left: 10%;
}


.download-page-img {
    text-align: center;
    padding: 24px;
    margin-top: 6rem;
    margin-bottom: 6rem;
}

.download-page-img img {
    padding: .5rem;
    max-width: 220px;
    background-color: white;
    border-radius: 50px;
}