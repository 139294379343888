@font-face {
    font-family: "Roboto-Light";
    src: url("./fonts/Roboto-Light.woff") format("woff"), /* Modern Browsers */
      url("./fonts/Roboto-Light.woff2") format("woff2"); /* Modern Browsers */
    font-weight: normal;
    font-style: normal;
  }
/* font converted using font-converter.net. thank you! */
@font-face {
  font-family: "Roboto-Bold";

  src: url("./fonts/Roboto-Bold.woff") format("woff"), /* Modern Browsers */
    url("./fonts/Roboto-Bold.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}
/* font converted using font-converter.net. thank you! */
@font-face {
  font-family: "Roboto-Regular";

  src: url("./fonts/Roboto-Regular.woff") format("woff"), /* Modern Browsers */
    url("./fonts/Roboto-Regular.woff2") format("woff2"); /* Modern Browsers */
  font-weight: normal;
  font-style: normal;
}