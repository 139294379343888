.paragraphs {
    color: white;
    margin: 0em;
    text-align: center;
}

.errorMessage {
    color: red;
    margin: 0.5em;
    font-size: small;
    text-align: center;
}

.hyperlinks {
    color: #ECA935;
}

.div-logo {
    margin-bottom: 50px;
    text-align: center;
}

.div-sign-in-box {
    margin-top: 24px;
}

.div-sign-in-box-background-left {
    min-height: 400px;
    min-width: 300px;
    width: 28%;
    padding: 20px;
    border-bottom-left-radius: 16px;
    border-top-left-radius: 16px;
    background: #343434;
    opacity: .75;
    font-weight: bold;
    font-family: 'Roboto-Bold', sans-serif;
    align-content: center;
}

.div-sign-in-box-background-right {
    min-height: 400px;
    min-width: 300px;
    width: 28%;
    padding: 20px;
    border-bottom-right-radius: 16px;
    border-top-right-radius: 16px;
    background: #343434;
    opacity: .75;
    font-weight: bold;
    font-family: 'Roboto-Bold', sans-serif;
}

.div-sign-in-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto; 
    margin-top: 7rem;
}

.sign-in-divider {
    border-left: 2px solid rgba(255, 255, 255, 0.65);
    min-height: 400px;
  }

.form-boxes {
    color: #959595;
    opacity: .75
}

.div-box {
    display: flex;
    flex-direction: column;
    gap: 16px;
    line-height: normal;
    margin-top: auto;
    font-size: small;
}

.div-sign-in-form
{
    margin-top: 2rem;
}

.login-page {
    display: flex;
    justify-content: center;
    height: 100vh;
    align-items: center;
}

.hyperlinks:hover {
    color: #ECA935;
    text-decoration: none;
}

.sign-in-button {
    height: 46px;
    border-color: black;
    line-height: normal;
    background-color: #FFFFFF;
    opacity: .75;
    text-decoration: none;
    padding: 0.1rem;
    text-align: center;
    border-radius: 16px;
    color: #000000;
    font-weight: bold;
    font-size: 14px;
}


.sign-in-page-input-group {
    margin-top: 1rem;
    padding: 0.2rem;
    background-color: #959595;
    border-radius: 22px;
    border: solid #FFFFFF 2px;
}

.sign-in-page-input-group span,
.sign-in-page-input-group input,
.sign-in-page-input-group button {
    background-color: transparent;
    border-radius: 30px;
    border: none;
    color: #FFFFFF;
    font-size: 14px;
}

.sign-in-page-input-group input::placeholder {
    color: #FFFFFF;
    font-weight: 700;
}

.sign-in-page-input-group input:focus {
    background-color: transparent;
    color: #FFFFFF;
    outline: none;
    box-shadow: none !important;
}

.sign-in-page-input-group input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px #959595 inset !important;
    -webkit-text-fill-color: #FFFFFF;
}

.idpButton-customizable {
    margin:0 auto;
    display:block;
}

.idp-container {
    padding: 0.5em;
}


@media (max-width: 750px) {
    .div-sign-in-container {
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      margin-top: 4rem;
    }
  
    .sign-in-divider {
        border-bottom: 2px solid rgba(255, 255, 255, 0.65);
        min-width: 300px;
        border-left: 0px;
        height: 2px;
        min-height: 2px;
    }
  
    .div-sign-in-box-background-left {
        border-bottom-left-radius: 0px;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
        min-height: 140px;
    }

    .div-sign-in-box-background-right{
        border-bottom-right-radius: 16px;
        border-top-right-radius: 0px;
        border-bottom-left-radius: 16px;
        min-height: 140px;
    }
  }