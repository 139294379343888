.space-app-row-os-button {
    border-radius: 20px !important;
    width: 8rem;
    padding: 0.7rem !important;
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
    background-color: #FFFFFF !important;
    color: #000000 !important;
    margin-right: 1rem !important;
    margin-bottom: 2rem !important;
    height: 3rem;
}

.active-space-app-os-button {
    border: 4px solid rgba(236, 169, 53, 1) !important;
    height: 3.1rem;
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
}

.space-app-row-enroll-button {
    border-radius: 20px !important;
    width: 8rem;
    padding: 0.7rem !important;
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
    background-color: rgba(236, 169, 53, 1) !important;
    color: #000000 !important;
    margin-right: 1rem !important;
    margin-bottom: 2rem !important;
    height: 3rem;
}

.enroll-button-disabled {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);
}

.enroll-button-diabled:hover {
    cursor: not-allowed !important;
}