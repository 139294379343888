.odd-row-container {
    display: flex;
    flex-direction: row;
    background-color: black;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}

.odd-row-left {
    width: 50%;
    min-height: 50vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    padding-right: 2rem;
    padding-left: 2rem;
    overflow-wrap: anywhere;
}

.odd-row-text {
    color: white;
}

.feature-instructions {
    text-align: left;
}

.odd-row-right {
    width: 50%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.odd-row-image {
    width: 85%;
    border-radius: 45px;

}

@media screen and (max-width: 480px) {
    .odd-row-container {
        flex-direction: column;
    }

    .odd-row-left {
        width: 100%;
        padding-right: 1rem;
    }

    .odd-row-right {
        width: 100%;
    }
}