.mb-page-title {
    text-align: center;
    color: white;
    font-size: 62px;
}

.main {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

@media screen and (max-width:640px) {
    .mb-page-title {
        font-size: 42px;
    }
}

