.unenroll-page {
    text-align: center;
    height: 100%;
    align-content: center;
}

@media screen and (max-width:767px) {
    .unenroll-page-title {
        font-size: 45px !important;
    }
}

.unenroll-page-title {
    color: white;
    margin-bottom: 1rem;
    font-size: 62px;
}

#unenrollment-result {
    width: 400px;
    min-width: 250px;
    color: white;
    background-color: rgba(52, 52, 52, 0.75);
    padding-left: 2rem;
    padding-right: 2rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    border-radius: 14px;
}

#unenrollment-result p {
    margin-bottom: 0;
    font-size: larger;
}

#unenrollment-result .MuiLinearProgress-colorPrimary {
    background-color: #ffffff;
}

#unenrollment-result .MuiLinearProgress-barColorPrimary {
    background-color: #ECA935;
}