.profile-page {
    display: flex;
    flex-direction: column;
    width: 100%;
    display: flex;
    justify-content: center;
}


.profile-page-title {
    text-align: center;
    color: white;
    margin-bottom: 8rem;
    font-size: 62px;
}

.main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}


@media screen and (max-width:450px) {
    .profile-page-title {
        margin-bottom: 3rem !important;
    }
}